<template>
  <div>
    <b-card style="display: grid;">
      <div class="container">
        <div class="row justify-content-between">
          <h3 class="col-sm-5 col-md-7">
            <feather-icon icon="ListIcon" size="20" />
            List of Scripts Availables
          </h3>
          <div class="col-sm-6 col-md-4 d-flex">
            <feather-icon icon="FilterIcon" size="25" class="mr-1" style="margin-top: 7px;" />
            <b-form-select v-model="filterTagName" :options="allTagsList">
              <template #first>
                <b-form-select-option :value="null" selected disabled>--- Filter By Technology ---</b-form-select-option>
              </template>
            </b-form-select>
            <b-button v-if="filterTagName" variant="outline-danger" @click="clearFilter" class="ml-1">
              <feather-icon icon="XCircleIcon" size="12"></feather-icon>
            </b-button>
          </div>
        </div>
      </div>
    </b-card>

    <div class="container">
      <div class="row mt-3 mb-1" v-if="marketplaceData.length > 0">
        <div class="col-md-4 col-sm-6" v-for="(code, index) in marketplaceData" :key="index">
          <b-card-group>
            <b-card :img-src="code.image_url" img-alt="Image" img-top position="relative">
              <b-badge pill variant="success" style="position: absolute; top: 10px; left: 10px; z-index: 1;"
                class="download-badge">
                {{ formatDownloads(code.downloads) }} <feather-icon icon='DownloadIcon' />
              </b-badge>
              <b-card-title style="margin-bottom: 2px !important; font-weight: 500; font-size: 1.5rem;">
                {{ code.name }}
              </b-card-title>
              <div class="container-fluid mb-2">
                <div class="row">

                  <div class="w-auto" v-for="(tag, index) in JSON.parse(code.tags)[0].split(',')" :key="index">
                    <b-badge variant="success" style="margin-inline:3px;" pill>
                      {{ tag }}
                    </b-badge>
                  </div>
                </div>
              </div>
              <hr />
              <div class="d-flex align-items-center justify-content-between">
                <h3 class="price text-success">{{ price }}</h3>
                <b-link @click="getDetailCode(code.id)">
                  <b-button v-ripple.400="'rgba(255, 159, 67, 0.15)'" variant="outline-success">
                    Preview <feather-icon icon="EyeIcon" />
                  </b-button>
                </b-link>
              </div>
            </b-card>
          </b-card-group>

        </div>
      </div>
      <div class="row mt-5 justify-content-center" v-else>
        <div class="col-md-6 col-sm-12">
          <div class="text-center">
            <feather-icon icon="AlertCircleIcon" size="80" />
            <h3 class="my-2" style="font-size: 3rem !important;">No data available yet</h3>
            <p class="h4">We're working on it! Check back soon.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
   
<script>
import {
  BContainer,
  BTable,
  BAvatar,
  BImg,
  BBadge,
  BCard,
  BRow,
  BCol,
  VBTooltip,
  BButtonGroup,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BCardGroup,
  BButton,
  BFormSelectOption,
  BModal,
  VBModal,
  BCardTitle,
  BFormFile,
  BLink,
  BCardText,
} from "bootstrap-vue";
import vSelect from "vue-select";
import axios from "@axios";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import Ripple from "vue-ripple-directive";
import router from "@/router";

export default {
  components: {
    BTable,
    BAvatar,
    'b-tooltip': VBTooltip,
    BCard,
    BCardGroup,
    BImg,
    BButtonGroup,
    BBadge,
    BRow,
    BLink,
    BCol,
    BFormGroup,
    BFormSelect,
    BCardTitle,
    BFormFile,
    vSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BModal,
    VBModal,
    BCardText,
    BFormSelectOption,
    BContainer,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      router,
      marketplaceData: [],
      price: 'Free',
      tags: [],
      filterTagName: null,
      allTagsList: [],

    }
  },
  watch: {
    filterTagName(value) {
      if (value) this.getListByTag(value);
      else this.getListCodes();
    },
  },
  mounted() {
    this.getListCodes();
    this.getAllTags();
  },
  created() {
  },
  computed: {

  },
  methods: {
    formatDownloads(downloads) {
      if (downloads >= 1000000) {
        return (downloads / 1000000).toFixed(1) + "M";
      } else if (downloads >= 1000) {
        return (downloads / 1000).toFixed(1) + "K";
      } else {
        return downloads.toString();
      }
    },
    getAllTags() {
      axios.post('/get-all-tags')
        .then(({ data }) => {
          if (data.status == "success") {
            this.allTagsList = data.tags;
          }
        })
    },
    getListByTag(tag) {
      axios.post('/get-mrktp-scripts-by-tag', {
        tag: tag
      })
        .then((response) => {
          this.marketplaceData = response.data.data;
        })
        .catch((error) => {
          console.error(error);
        })
    },
    clearFilter() {
      this.filterTagName = null;
    },
    goToTagFilterPage(tagName) {
      router.push({ name: "ScriptsByTag", params: { tag: tagName } })
    },
    getDetailCode(parameter) {
      router.push({ name: "marketplaceDetail", params: { id: parameter } });
    },
    getListCodes() {
      axios.post('/get-mrktp-scripts')
        .then((response) => {
          this.marketplaceData = response.data.data;
        })
        .catch((error) => {
          console.error(error);
        })
    }
  },
}
</script>

<style scoped>
.price {
  font-weight: bold;

}

/* Media query for smaller screens */
@media (max-width: 767px) {
  .download-badge {
    position: static;
    margin-bottom: 10px;
  }
}
</style>